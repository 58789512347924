.__user-invitation {
  padding: 20px;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;

  --text-color: #5F5F5F;
  --text-light: #838383;
  --link-color: #1C72D7;
  --loading-bg: #e3e3e3;
  --loading-bg-2: #eeeeee;
  --error-color: #FE0707;

  h1 {
    text-transform: uppercase;
    color: #4C62FB;
    text-align: center;
    position: absolute;
    top: 24px;
    font-weight: 600;
    font-size: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
  }

  .invitation-content {
    margin: 0 auto;
    max-width: 100%;
    width: 592px;
    padding: 48px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 4px 12px 0px rgba(black, 0.1);
    color: var(--text-color);
    text-align: center;

    h3 {
      font-style: italic;
      font-size: 20px;
      line-height: 32px;
      margin-block: 24px 36px;
    }

    .__user-avatar {
      justify-content: center;

      &-icon {
        width: 72px;
        height: 72px;
        font-size: 32px;
      }
    }

    .description {
      font-size: 18px;
      line-height: 26px;
      color: var(--text-light);
    }

    .__button-primary {
      padding-inline: 40px;
      display: inline-block;
      min-width: 210px;
    }

    p.create-account {
      margin-top: 24px;

      a {
        display: inline-block;
        text-decoration: none;
        color: var(--link-color);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .logged-as {
      font-style: italic;
      margin-top: 20px;
    }

    .error-icon {
      width: 72px;
      height: 72px;
      
      path {
        fill: var(--error-color);
      }
    }

    .error-accept {
      color: var(--error-color);
      margin-top: 32px;
    }
  }

  .loading {
    &-avatar {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      display: inline-block;
      margin-bottom: 32px;
      background-color: var(--loading-bg);
    }

    &-text {
      margin-block: 20px;
      border-radius: 8px;
      background-color: var(--loading-bg);
      width: 100%;
      height: 20px;
      background: linear-gradient(126deg, var(--loading-bg) 40%, var(--loading-bg-2) 45%, var(--loading-bg-2) 50%, var(--loading-bg) 55%);
      animation: --user-invite-loading 1.5s ease infinite;
      background-size: 200%;

      &:last-of-type {
        width: 80%;
      }
    }
  }
}

@keyframes --user-invite-loading {
  from {
    background-position: -10%;
  }
  to {
    background-position: 110%;
  }
}