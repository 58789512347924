.__leads-list {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto;

  --loading-bg: #e3e3e3;
  --loading-bg-2: #eeeeee;

  .content {
    padding: 0 40px;
    margin: 20px auto;
    width: 100%;

    @media screen and ((max-width: 1440px) or (max-height: 920px)) {
      margin-block: 12px;
      padding-inline: 12px;
    }
  }

  .controls {
    display: flex;
    justify-content: space-between;

    .title-category {
      display: flex;
      gap: 8px;
      align-items: center;

      .leads-icon {
        background-color: #4C62FB;
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        > svg {
          width: 24px;
          height: auto;
          fill: white;
        }
      }

      h2 {
        color: #5F5E5E;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: 24px;
        line-height: 26px;
        font-weight: 600;

        [data-theme=dark] & {
          color: white;
        }
      }

      .breadcrumbs {
        color: #969696;
        font-size: 15px;
        height: 24px;
        overflow: hidden;

        [data-theme=dark] & {
          color: #c5c5c5;
        }

        .text-content {
          transition: 0.2s transform;
        }

        .all-leads {
          transform: translateY(-22px);  
        }
      }
    }
    

    .actions {
      display: flex;
      gap: 8px;
      align-items: center;
      
      button.main-actions {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        background-color: #F1F1F1;
        color: #797979;
        border-radius: 32px;
        padding: 6px 20px;
        transition: 0.3s background-color;
        font-size: 15px;
        align-items: center;
        gap: 8px;

        [data-theme=dark] & {
          color: #EEEEEE;
          background-color: transparent;
          border: 1px solid #C4C8CC;
        }

        > svg {
          width: 16px;
          height: 16px;
          fill: #838383;

          [data-theme=dark] & {
            fill: #F1F1F1;
          }
        }

        &:hover:not(.active) {
          background-color: #E2E2E2;

          [data-theme=dark] & {
            background-color: #454750;
          }
        }

        &.active {
          background-color: #4C62FB;
          color: white;

          &:hover {
            background-color: #3b55ff;
          }

          > svg {
            fill: white;
          }
        }
      }

      .actions-dropdown {
        .dropdown-menu {
          min-width: 120px;
        }

        .dropdown-item {
          display: flex;
          align-items: center;
          gap: 12px;
          font-size: 15px;

          .import-icon {
            width: auto;
            height: 20px;

            path {
              fill: #969696;
            }
          }
          
          .export-icon {
            fill: #969696;
            width: auto;
            height: 20px;
            margin-bottom: 4px;
          }

          .trash-icon {
            width: 18px;
            height: auto;
            fill: #838383;
            margin-inline: 1px;
            margin-bottom: 3px;
  
            [data-theme=dark] & {
              fill: #F1F1F1;
            }
          }
        }
      }
    }
  }

  .leads-table {
    overflow: auto;
    font-size: 14px;
    flex: 1;

    .table-overflow {
      min-width: 100%;
    }

    .bulk-action, .delete-lead {
      flex-shrink: 0;
      width: 40px;
    }
  }

  .empty-leads {
    border: 1px dashed #9D9DB1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #888888;
    margin-block: 20px;
    padding-block: 96px;

    h3 {
      font-size: 20px;
      font-weight: bold;
    }

    p {
      font-size: 16px;
      font-style: italic;
      margin-bottom: 0;
    }

    a, button {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      font-style: italic;
      color: #469BFF;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .table-head {
    color: #5C5C5C;
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    padding: 8px 0;
    background-color: #F2F2F6;
    border-radius: 8px;
    gap: 4px;

    [data-theme=dark] & {
      border: 1px solid #818990;
      background-color: #25282b;
      color: white;

      border: 1px solid #818a92;
      background-color: #1b1c1d;
      color: white;
    }

    > div {
      min-width: 0;

      .toggle {
        padding: 4px 8px;
      }

      .bulk-toggle-form {
        position: relative;

        .partial-selection {
          width: 8px;
          height: 2px;
          // border-radius: 2px;
          background-color: #BBBBBB;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          pointer-events: none;
        }
      }

      &.bulk-action {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 4px;
      }

      &.status {
        text-align: center;
      }
    }
  }
    
  .table-body {
    color: #3A3A3A;

    [data-theme=dark] & {
      color: #dddee2;
    }

    .infinite-loader {
      .skeleton-row {
        display: flex;
        padding-block: 12px;
        padding-left: 12px;
        border-bottom: 1px solid #F2F2F6;
      }

      .skeleton-field {
        background-color: red;
        height: 16px;
        width: 60%;
        background: linear-gradient(126deg, var(--loading-bg) 40%, var(--loading-bg-2) 45%, var(--loading-bg-2) 50%, var(--loading-bg) 55%);
        animation: --lead-list-loading 1.5s ease infinite;
        background-size: 200%;
        border-radius: 4px;
      }
    }
    
    .table-row {
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      gap: 4px;

      .non-editable {
        padding-left: 8px;
      }
      
      &:hover {
        .bulk-action {
          .toggle-container {
            opacity: 1;
          }

          .bulk-toggle {
            input[type=checkbox] {
              opacity: 1;
            }
          }
        }

        .delete-btn {
          opacity: 1;
        }

        .__lead-dropdown {
          .label.unassigned {
            opacity: 1;
          }
        }
      }

      &:first-child {
        > div {
          padding-top: 16px;
        }

        &:hover:not(.selected) {
          background: linear-gradient(to bottom, transparent 0, transparent 15%, #F8F8F8 15%, #F8F8F8 100%);
          
          [data-theme=dark] & {
            background: linear-gradient(to bottom, transparent 0, transparent 15%, #2f3236 15%, #2f3236 100%);
          }
        }
        
        &.selected {
          background: linear-gradient(to bottom, transparent 0, transparent 15%, #E2EFFF 15%, #E2EFFF 100%);

          [data-theme=dark] & {
            background: linear-gradient(to bottom, transparent 0, transparent 15%, #224fbc 15%, #224fbc 100%);
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #F2F2F6;

        [data-theme=dark] & {
          border-bottom-color: #3f3f40;
        }

        &.selected {
          border-bottom: 1px solid #bedbff;

          [data-theme=dark] & {
            border-block-color: #5283f9;
          }
        }
      }
      
      &:not(:first-child) {
        &:hover:not(.selected) {
          background-color: #f8f8f8;

          [data-theme=dark] & {
            background-color: #2f3236;
          }
        }

        &.selected {
          background-color: #E2EFFF;

          [data-theme=dark] & {
            background-color: #224fbc;
          }
        }
      }

      > div {
        padding: 4px 0px;
        min-width: 0;

        .__editable-field.editing input {
          font-size: 14px;

          &.form-control.is-invalid, .was-validated .form-control:invalid {
            padding-right: 32px;
          }
        }
        
        &:hover {
          .__editable-field {
            button.edit {
              opacity: 1;
            }

            .empty-field-add {
              opacity: 1;
            }
          }

        }

        &.date-added {
          text-align: end;
        }

        &.bulk-action {
          .toggle-container {
            display: flex;
            margin-left: 4px;
            margin-right: 2px;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            align-items: center;
            justify-content: center;

            &:hover {
              background-color: rgba(#63AAFF, 0.1);
            }
          }

          .bulk-toggle {
            input[type=checkbox] {
              opacity: 0;

              &:checked {
                opacity: 1;
              }
            }
          }
        }

        .delete-btn {
          all: unset;
          box-sizing: border-box;
          cursor: pointer;
          border-radius: 100%;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          opacity: 0;

          &:hover {
            background-color: rgba(#63AAFF, 0.1);

            > svg {
              fill: #63AAFF;
            }
          }

          > svg {
            width: auto;
            height: auto;
            fill: #969696;
          }
        }
      }
    }
  }

  input[type=checkbox] {
    display: block;
    margin: 0 auto;
    width: 16px;
    height: 16px;
    cursor: pointer;
    
    &:not(:checked) {
      border-color: #BBBBBB;
    }

    &:focus {
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(#3693FF, 0.2);
    }

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}

@keyframes --lead-list-loading {
  from {
    background-position: -10%;
  }
  to {
    background-position: 110%;
  }
}