.__navigation {
  width: 252px;
  top: 0;
  bottom: 0;
  left: 0;
  position: relative;
  transition: 0.5s width;
  display: flex;

  --hover-bg: #ECEEFC;
  --bg-color: #F7F7FA;
  --border-color: #DEDEDE;
  --border-color-2: #D8D8E9;

  [data-theme=dark] & {
    --hover-bg: #383838;
    --bg-color: #141617;
    --border-color: #414144;
    --border-color-2: #414144;
  }

  &:hover {
    .toggle-navigation {
      opacity: 1;
    }
  }

  .inner-container {
    background-color: var(--bg-color);
    color: #4A4A4A;
    border-right: 2px solid var(--border-color);
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    [data-theme=dark] & {
      
      color: #f0f0f0;
    }
  }

  .toggle-area {
    position: absolute;
    width: 12px;
    height: 100%;
    right: 0;
    transform: translateX(50%);
  }

  .view-archived {
    all: unset;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 15px;
    padding: 6px 8px;
    margin-block: 8px 4px;
    font-weight: 600;
    border-radius: 4px;
    width: 100%;
    
    &:hover {
      background-color: rgba(white, 0.3);
    }

    &:active {
      background-color: rgba(white, 0.6);
    }

    .archive-icon {
      width: 16px;
      height: 16px;

      path {
        fill: #4C62FB;
      }
    }

    .archived-text {
      flex: 1;
    }

    .archive-chevron {
      color: #7C7C7C;
      width: 12px;
      height: auto;
      transition: 0.3s transform;

      &.flipped {
        transform: rotate(180deg);
      }
    }
  }

  &.collapsed {
    width: 72px;

    // The dropdown menu cannot be seen when overflow = hidden.
    // After the collapse animation is done, set overflow to visible
    &-enter-done {
      .inner-container {
        overflow: visible;
      }
    }

    .toggle-navigation {
      > svg {
        transform: rotate(180deg);
      }
    }

    nav {
      overflow: visible;
    }

    h1 {
      opacity: 0;
      max-width: 0;
      margin-block: 4px;
    }

    .main-route {
      padding: 12px 4px;
      font-size: 12px;
      text-align: center;
      flex-shrink: 1;

      .main-link {
        margin: 0;
      }

      svg.link-icon {
        display: block;
        margin: 4px auto;
      }

      .sub-link {
        display: none;
      }  
    }

    .user-settings {
      padding: 16px 0;

      .profile-picture {
        width: 40px;
        height: 40px;
        margin: 0 auto;
      }

      .user-name-account {
        display: none;
      }
  
      .expand {
        display: none;
      }
    }

    .__business-toggle {
      margin-bottom: 48px;

      .toggle-overflow {
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 8px;

        > .business-logo {
          margin: 0 auto;
          width: 32px;
          height: 32px;
        }
  
        > .business-name {
          display: none;
        }
  
        .chevron {
          display: none;
        }
      }
    }
  }
  
  .overlay-gradient {
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 20%;
    right: 0;
    bottom: 0;
    background: linear-gradient(270deg, rgba(black, 0.05) 0%, transparent 100%), transparent;
  }

  .toggle-navigation {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 0;
    background-color: white;
    border: 2px solid var(--border-color);
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;
    margin-top: 2px;
    opacity: 0;
    z-index: 1001;
    transform: translateX(50%);

    &:hover {
      border-color: #bfbfc6;
      box-shadow: 0 3px 0 0 var(--border-color);
      margin-top: 0;
    }

    > svg {
      fill: #AAAAB2;
      width: 12px;
      height: 12px;
    }
  }

  h1 {
    text-align: center;
    margin-top: 32px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 48px;
    max-width: 320px;
    white-space: nowrap;
    transition: 0.3s all;

    a {
      color: #4C62FB;
      text-decoration: none;

      [data-theme=dark] & {
        color: #f0f0f0;
      }

      &:hover {
        filter: brightness(1.1);
      }
    }
  }

  nav {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-shrink: 100px;
    overflow: auto;

    a {
      text-decoration: none;
      color: inherit;
      display: block;
    }
    
    .main-route {
      padding: 20px 16px;
      white-space: nowrap;
      overflow: hidden;
      user-select: none;
      flex-shrink: 0;

      &:hover:not(.active):not(.active-lead) {
        background-color: var(--hover-bg);
      }

      &.main-categories {
        overflow: auto;
        flex-shrink: inherit;
      }

      &.active-lead {
        background-color: #E4E7FF;

        [data-theme=dark] & {
          background-color: #4c62fb;
          color: #E4E7FF;
        } 
      }
      
      &.active, .main-link.active {
        font-weight: 600;
        letter-spacing: -0.1px;
        background-color: #E4E7FF;
        color: #4C62FB;

        [data-theme=dark] & {
          background-color: #4c62fb;
          color: #E4E7FF;
        }

        .link-icon {
          fill: #8796FF;
        }
      }

      .categories {
        overflow: auto;
      }

      .main-link {
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
      }

      svg.link-icon {
        width: 20px;
        height: auto;
        margin-right: 12px;
        fill: #B4B4B4;
        vertical-align: text-top;
        transition: 0.2s fill;
      }

      .sub-link {
        display: block;
        padding: 4px 8px;
        padding-inline-start: 32px;
        margin-block: 4px;
        border-radius: 4px;
        transition: 0.3s background-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #7C7C7C;
        font-size: 15px;

        &.active {
          background-color: white;
          box-shadow: 0 0 2px 0 rgba(black, 0.2);
        }

        &:hover:not(.active) {
          background-color: rgba(white, 0.5);
        }

        &.view-all {
          color: #5C5C5C;
          font-weight: 600;
        }
      }
    }
  }

  .user-settings {
    display: flex;
    padding: 24px 12px 24px;
    border-top: 1px solid var(--border-color-2);
    width: 100%;
    text-decoration: none;
    color: inherit;
    gap: 8px;
    user-select: none;
    align-items: center;
    background-color: var(--bg-color);

    &:hover {
      background-color: var(--hover-bg);
    }

    .profile-picture {
      background-color: black;
      border-radius: 4px;
      color: white;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }

    .user-name-account {
      flex: 1;
    }
    
    .user-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      font-size: 14px;
    }

    .user-account {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #7C7C7C;
    }
  }

  .leads-dropdown {
    .dropdown-menu {
      border-color: #E5E5E5;
      animation: __navigation-leads-show-menu 0.2s forwards;
      border-radius: 8px;
      padding: 12px 8px;
      min-width: 240px;
      max-width: 320px;
      border: 1px solid #ECECEC;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
      max-height: 70vh;
      overflow: auto;

      .categories-title {
        text-transform: uppercase;
        color: #A8A8A8;
        padding: 2px 6px 4px;
        font-size: 12px;
      }
  
      .dropdown-item {
        color: #5C5C5C;
        font-size: 14px;
        padding: 6px;
        border-radius: 4px;
        margin-block: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.view-all {
          font-weight: bold;
        }

        &:active {
          background-color: #EEEEEE;
        }

        &.active {
          background-color: #E4E7FF;
          color: #4C62FB;
          font-weight: 600;
        }
      }

      .dropdown-divider {
        border-top-color: #E4E4E4;
      }
    }
  }
}

@keyframes __navigation-leads-show-menu {
  from {
    opacity: 0;
    transform: translate3d(52px, 0, 0);
  }

  to {
    opacity: 1;
    // bootstrap original: translate3d(60px, 0px, 0px)
    transform: translate3d(64px, 0, 0);
  }
}
