.__editable-field {
  display: flex;
  justify-content: space-between;
  gap: 2px;
  // padding-top: 5px;
  // padding-bottom: 6px;
  padding-block: 4px;
  padding-left: 8px;
  padding-right: 2px;
  align-items: center;
  border: 1px solid transparent;
  position: relative;

  --bg-color: white;

  [data-theme=dark] & {
    --bg-color: #212529;
  }

  .field-label {
    position: absolute;
    top: -8px;
    left: 4px;
    color: #8A8A8A;
    font-size: 10px;
    background-color: var(--bg-color);
    text-transform: uppercase;
    padding-inline: 2px;
    border-radius: 2px;
    animation: 0.3s forwards __editable-field-input-label;

    &.last-field {
      left: 43%;
    }
  }

  &.editing {
    border-color: #84BCFF;
    box-shadow: 0 0 0 3px rgba(#3693FF, 0.1);
    border-radius: 4px;
    padding-block: 0;
    gap: 4px;
    background-color: var(--bg-color);
    transition-property: box-shadow, border-color, background-color;
    transition-duration: 0.2s;
    padding-right: 4px;
    cursor: initial;

    .invalid-tooltip {
      transform: translateY(4px);
    }

    .input-container {
      flex: 1;
    }

    input {
      padding: 8px 0;
      width: 100%;
      cursor: text;
      line-height: 20px;
      border: none;
      font-size: 15px;
      box-shadow: none;
    }

    .field-divider {
      margin-right: 8px;
      color: #ACACAC;
      font-size: 18px;
    }

    .actions {
      padding-inline: 4px;
      display: flex;
      gap: 4px;
      transform: translateX(30px);
      animation: __editable-field-actions 0.2s forwards;
      
      button {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;

          &.close {
            fill: #AFAFAF;
          }

          &.save {
            fill: #63AAFF;
          }
        }
      }
    }
  }

  .field {
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    text-overflow: ellipsis;
    padding-block: 4px;
  }

  .empty-field-add {
    opacity: 0;
    padding: 4px 8px;
    font-size: inherit;
    border: none;

    text-decoration: none;

    &:hover {
      background-color: rgba(#63AAFF, 0.1);
    }
  }

  .saving {
    color: #CECECE;
  }

  button.edit {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    opacity: 0;
    padding: 7px;
    margin-bottom: 1px;
    border-radius: 100%;
    
    &:hover {
      background-color: rgba(#63AAFF, 0.1);
      
      svg {
        fill: #63AAFF;
      }
    }

    svg {
      pointer-events: none;
      width: 14px;
      height: 14px;
      fill: #CECECE;
      display: block;
    }
  }
}

@keyframes __editable-field-actions {
  from {
    transform: translateX(30%);
    opacity: 0.5;
  }

  to {
    transform: unset;
    opacity: 1;
  }
}

@keyframes __editable-field-input-label {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}
