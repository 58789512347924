.__lead-dropdown {
  font-weight: 700;
  font-size: 13px;
  color: var(--text-color);

  --text-color: #666;
  --text-light: #9D9DB1;
  --bg-color: white;
  --border-color: #E2E2E2;
  --text-dim-color: #6d6d6d;
  --active-bg: #E4E7FF;
  --active-color: #4C62FB;
  
  [data-theme=dark] & {
    --text-color: #dddee2;
    --bg-color: #2e2f30;
    --border-color: #a9a9a9;
    --text-dim-color: #b7b7b7;
    --chevron-color: #a9a9a9;
    --active-bg: #2e47f3;
    --active-color: #E4E7FF;

    font-weight: 600;
  }

  &.__lead-dropdown-discreet {
    .lead-toggle {
      border-color: transparent;
      
      &:hover, &.show {
        background-color: var(--bg-color);
        border-color: var(--border-color);
  
        svg.chevron {
          opacity: 1;
          transform: rotate(180deg);
        }
      }

      svg.chevron {
        opacity: 0;
        transform: rotate(180deg) translateY(-50%);
        transition-duration: 0.2s;
        transition-property: transform, opacity;
      }
    }

    .label.unassigned {
      opacity: 0;
    }
  }

  &.__lead-dropdown-large {
    font-size: 16px;
    font-weight: normal;

    &.__lead-dropdown {
      .dropdown-menu {
        max-height: 40vh;

        .dropdown-item {
          padding: 6px 10px;
        }

        .dropdown-item:not(.create-new) {
          font-weight: normal;
          font-size: 15px;
        }

        .dropdown-item-text {
          font-size: 15px;
        }
      }

      .square {
        width: 16px;
        height: 16px;
      }

      .lead-toggle {
        border-radius: 6px;
      }

      .toggle-overflow {
        padding: 8px 12px;
      }
    }
  }

  .lead-toggle {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-width: 100px;
    width: 100%;
    
    border: 1px solid var(--border-color);
    border-radius: 4px;
    white-space: nowrap;

    &::after {
      content: none;
    }

    .toggle-overflow {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 4px 8px;
    }

    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;

      &.unassigned {
        color: var(--text-dim-color);
        font-style: italic;
      }
    }

    svg.chevron {
      width: 12px;
      height: 12px;
      margin-top: 4px;
      transform: rotate(180deg);
      
      path {
        fill: var(--chevron-color);
      }
    }
  }

  .square {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: black;
    flex-shrink: 0;
  }

  .status-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-color {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    font-size: 11px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-shrink: 0;
  }

  .label {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .dropdown-menu {
    border-color: #E5E5E5;
    min-width: 100%;
    overflow: auto;
    max-height: 30vh;
    padding: 4px 6px;

    .dropdown-item {
      display: flex;
      font-weight: 700;
      font-size: 13px;
      line-height: 24px;
      gap: 6px;
      align-items: center;
      color: var(--text-color);
      padding: 4px 8px;
      margin-block: 2px;
      border-radius: 4px;

      &.active, &:active {
        background-color: var(--active-bg);
        color: var(--active-color);
      }

      &.create-new {
        display: block;
        background-color: #F1F1F1;
        width: 78%;
        margin: 0 auto;
        border-radius: 32px;
        font-size: 14px;
        text-align: center;
        font-weight: normal;

        &:hover {
          background-color: #E2E2E2;
        }
      }

      .user-color {
        margin-block: 2px;
      }
    }

    .dropdown-item-text {
      font-size: 13px;
    }

    .empty-text {
      color: var(--text-light);
      text-align: center;
      font-style: italic;
    }

    .dropdown-divider {
      border-color: #E5E5E5;
    }
  }
}
